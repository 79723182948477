import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { ReactTinyLink } from 'react-tiny-link';

import './assets/scss/main.scss';

import Header from './components/Header';
import icon from './components/Images';

import apple from './assets/images/apple.png';
import android from './assets/images/android.png';
import link from './assets/images/link.png';

/* eslint-disable jsx-a11y/anchor-is-valid */
class App extends Component {
  render() {
    const siteTitle = "Calvin Wong's Portfolio";
    const siteDescription = 'About me';

    return (
      <div className='App'>
        <Header />
        <Helmet>
          <title>{siteTitle}</title>
          <meta name='description' content={siteDescription} />
        </Helmet>
        <div id='main'>
          <section id='one'>
            <h2>About Me</h2>
            <p className='about_font'>
            An experienced iOS engineer who loves to deliver high-quality products.<br/>
            Seeking to leverage strong execution capabilities and extensive industry knowledge to challenge myself as my next career goal.
            </p>
          </section>
          <section id='two'>
            <h2>Current Projects</h2>
            <div className='icon_text_div'>
              {icon('payme')}
              <a className='image icon'>
                <h3 className='h4_bold'>PayMe by HSBC</h3>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://apps.apple.com/us/app/payme-from-hsbc/id1184264977'
                >
                  <img className='inline_icon' alt='' src={apple} />
                </a>
                &nbsp; &nbsp;
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://play.google.com/store/apps/details?id=hk.com.hsbc.paymefromhsbc&hl=en'
                >
                  <img className='inline_icon' alt='' src={android} />
                </a>
                <h4 className='h5_font_weight'>
                  <ul>
                    <li>
                    A leading mobile wallet in Hong Kong, serving 3 millions users out of 7.3 millions population with a market share more than 50%
                    </li>
                    <li>Deliver new key features and maintain the app stability by:
                    <ul>
                        <li>solving bugs</li>
                        <li>clearing tech debt</li>
                        <li>adding unit tests</li>
                        <li>adding automation tests etc.</li>
                    </ul>
                    </li>
                    <li>
                    Work for tasks that are focused on making the app more performant and having better user experience, e.g. 
                    </li>
                    <ul>
                        <li>reducing app launch time</li>
                        <li>reducing app size</li>
                        <li>enhancing APIs handling to reduce loading time etc.</li>
                    </ul>
                    <li>Perform processes and manage app releases in compliance with the bank’s standard, including to handle:
                    <ul>
                        <li>code scan result</li>
                        <li>penetration test result</li>
                        <li>accessibility test result</li>
                        <li>change request management</li>
                        <li>release monitoring</li>
                        <li>on-call production support etc.</li>
                    </ul>
                    </li>
                    <li>
                    Work with product owners, delivery teams and core testing teams across UK, HK and China
                    </li>
                  </ul>
                </h4>
              </a>
            </div>
            <h2>Previous Projects</h2>
            <div className='icon_text_div'>
              {icon('mw')}
              <a className='image icon'>
                <h3 className='h4_bold'>MarketWatch - News & Data</h3>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://apps.apple.com/app/marketwatch/id336693422'
                >
                  <img className='inline_icon' alt='' src={apple} />
                </a>
                &nbsp; &nbsp;
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://play.google.com/store/apps/details?id=com.marketwatch&hl=en'
                >
                  <img className='inline_icon' alt='' src={android} />
                </a>
                <h4 className='h5_font_weight'>
                  <ul>
                    <li>
                    A popular finanical news brand for investors, having ~1 million monthly active users on the platform 
                    </li>
                    <li>
                    Contributed to 3 financial news iOS apps of the company - MarketWatch, Barron’s, and Financial News
                    </li>
                    <li>
                    Shipped new features for the apps using Swift and the company’s internal core frameworks  
                    </li>
                    <li>
                    Maintained these apps stability by solving legacy bugs and refactoring, and ensured their healthiness by keeping a close eye on the crash-free sessions using Firebase Crashlytics
                    </li>
                    <li>
                    Migrated CI/CD platform from BuddyBuild to Bitrise successfully for faster delivery pipelines   
                    </li>
                    <li>
                    Reviewed App Store comments and addressed problems frequently for better user experience  
                    </li>
                    <li>
                    Performed A/B testing to analyze user behavior for better subscriber conversion rate
                    </li>
                    <li>
                    Worked with product owners and core engineering teams in the US and UK
                    </li>
                  </ul>                  
                </h4>
              </a>
            </div>
            <div className='icon_text_div'>
              {icon('barrons')}
              <a className='image icon'>
                <h3 className='h4_bold'>Barron’s - Investing Insights</h3>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://apps.apple.com/us/app/barrons-investing-insights/id409882593'
                >
                  <img className='inline_icon' alt='' src={apple} />
                </a>
                &nbsp; &nbsp;
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://play.google.com/store/apps/details?id=com.barrons.us&hl=en'
                >
                  <img className='inline_icon' alt='' src={android} />
                </a>
                <h4 className='h5_font_weight'>
                  <li>
                  A finanical news brand which targets at seasoned investors in the US
                  </li>
                </h4>
              </a>
            </div>
            <div className='icon_text_div'>
              {icon('fn')}
              <a className='image icon'>
                <h3 className='h4_bold'>Financial News</h3>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://apps.apple.com/gb/app/financial-news/id1446852392'
                >
                  <img className='inline_icon' alt='' src={apple} />
                </a>
                &nbsp; &nbsp;
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://play.google.com/store/apps/details?id=com.fnlondon&hl=en_US'
                >
                  <img className='inline_icon' alt='' src={android} />
                </a>
                <h4 className='h5_font_weight'>
                  <li>
                  A finanical news brand which targets at the UK audience
                  </li>
                </h4>                
              </a>
            </div>
            <div className='icon_text_div'>
              {icon('gumtree')}
              <a className='image icon'>
                <h3 className='h4_bold'>Gumtree: Find local ads & jobs</h3>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://itunes.apple.com/au/app/id336693856'
                >
                  <img className='inline_icon' alt='' src={apple} />
                </a>
                &nbsp; &nbsp;
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://play.google.com/store/apps/details?id=com.ebay.gumtree.au'
                >
                  <img className='inline_icon' alt='' src={android} />
                </a>
                &nbsp; &nbsp;
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.gumtree.com.au'
                >
                  <img className='inline_icon' alt='' src={link} />
                </a>
                <h4 className='h5_font_weight'>
                <ul>
                  <li>
                  Gumtree Australia, an eBay classified advertising business at the time, serving millions of active
users as one of the most popular online Australia marketplaces
                  </li>
                  <li>
                  Delivered new features for iOS using Swift and web products using ReactJS
                  </li>
                  <li>
                  Maintained the flagship app for at least 99.98% crash-free sessions, and achieved an average rating of 4.6+ stars
                  </li>
                  <li>
                  Implemented CI/CD pipelines for both iOS and Android platforms using Jenkins and Fastlane, successfully reduced a significant amount of time for manual build processes, hence faster delivery time
                  </li>
                  <li>
                  Worked with product, engineering and QA teams across Australia, the US, and China
                  </li>
                </ul>                  
                </h4>
              </a>
            </div>     
            <h2>Personal Projects</h2>
            <div className='icon_text_div'>
              {icon('hktraffic')}
              <a className='image icon'>
                <h3 className='h4_bold'>HK Traffic News</h3>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://itunes.apple.com/us/app/id1405991853'
                >
                  <img className='inline_icon' alt='' src={apple} />
                </a>
                &nbsp; &nbsp;
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://play.google.com/store/apps/details?id=com.hktraffic.news'
                >
                  <img className='inline_icon' alt='' src={android} />
                </a>
                &nbsp; &nbsp;
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://hk-traffic-news.web.app'
                >
                  <img className='inline_icon' alt='' src={link} />
                </a>
                <h4 className='h5_font_weight'>
                  <ul>
                    <li>
                    A project that aims to provide local traffic information to Hong Kong people for their daily lives
                    </li>
                    <li>
                    Available on iOS and Android. Both platforms are developed from scratch using React Native and{' '}
                      <a
                        href={'https://expo.io'}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Expo.io
                      </a>
                    </li>
                    <li>
                    Making use of Google and Firebase services like Analytics, Crashlytics, Hosting and AdMob  
                    </li>
                    <li>
                    Serving thousands of active users monthly 
                    </li>
                  </ul>                  
                </h4>
              </a>
            </div>
            <div className='icon_text_div'>
              {icon('medium')}
              <a className='image icon'>
                <h3 className='h4_bold'>Medium Contributor</h3>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://medium.com/@calvinw'
                >
                  <img className='inline_icon' alt='' src={link} />
                </a>
                <h4 className='h5_font_weight'>
                  <ul>
                    <li>
                    Contribute to the community by sharing insights from my development experience using Medium as a platform
                    </li>
                    <li>
                    Accumulated more than 80k views for all published development related articles  
                    </li>
                  </ul>                  
                </h4>
                <br />
                <ReactTinyLink
                  cardSize='small'
                  maxLine={2}
                  minLine={1}
                  header={
                    'Using URLSession to retrieve JSON in Swift (1) - Getting Started'
                  }
                  description={
                    'This article is to demonstrate how to retrieve JSON data from a HTTP Web API in iOS development.'
                  }
                  defaultMedia={
                    'https://miro.medium.com/focal/1200/632/48/39/0*8n4esK9s-3m4ztqx'
                  }
                  url='https://calvinw.medium.com/using-urlsession-to-retrieve-json-in-swift-1-getting-started-d929f3a49c67'
                />
                <br />
                <ReactTinyLink
                  cardSize='small'
                  maxLine={2}
                  minLine={1}
                  header={'Using UITableView in Swift (2) — with RxSwift'}
                  description={
                    'As we have gone through the basic usage of UITableView in the previous article, this time e will transform that example into a project…'
                  }
                  defaultMedia={
                    'https://miro.medium.com/max/1200/0*NmfLj0nGzWatxNLr'
                  }
                  url='https://calvinw.medium.com/using-uitableview-in-swift-2-with-rxswift-3e6dac456b92'
                />
                <br />
                <ReactTinyLink
                  cardSize='small'
                  maxLine={2}
                  minLine={1}
                  header={
                    'Using Combine for URLSession in Swift (1) — replacing RxSwift'
                  }
                  description={
                    'This series is to illustrate the use of Swift Combine - the official reactive framework by Apple. As we have demonstrated using RxSwift to…'
                  }
                  defaultMedia={
                    'https://miro.medium.com/max/1200/0*fgybChOm5tMyImcT'
                  }
                  url='https://calvinw.medium.com/using-combine-for-urlsession-in-swift-1-replacing-rxswift-2cd022f57121'
                />
              </a>
            </div>
          </section>
          <section id='three'>
            <h2>Tech Stack</h2>
            <h3>Programming Languages</h3>
            {icon('swift')}
            {icon('react')}
            {icon('javascript')}
            {icon('html')}
            {icon('css')}
            {icon('node')}
            <h3>Tools & Platforms</h3>
            {icon('firebase')}
            {icon('ga')}
            {icon('appstore')}
            {icon('googleplay')}
            {icon('fastlane')}
            {icon('bb')}
            {icon('jenkins')}
            {icon('slack')}
            {icon('github')}
            {icon('jira')}
          </section>
          <section id='four'>
            <h2>Get In Touch</h2>
            <h4 className='h5_font_weight'>Messages are very welcome through Linkedin.</h4>
          </section>
        </div>
      </div>
    );
  }
}

export default App;
