import React from 'react';

const Footer = () => {
  return (
    <div id='footer'>
      <div className='inner'>
        <ul className='icons'>
          <li>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.linkedin.com/in/calvinwkl/'
              className='icon fa-linkedin'
            >
              <span className='label'>LinkedIn</span>
            </a>
          </li>
          <li>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://github.com/calvinwkl'
              className='icon fa-github'
            >
              <span className='label'>Github</span>
            </a>
          </li>
          <li>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://medium.com/@calvinw'
              className='icon fa-medium'
            >
              <span className='label'>Github</span>
            </a>
          </li>
          <li>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://stackoverflow.com/users/9903723/calvin?tab=profile'
              className='icon fa-stack-overflow'
            >
              <span className='label'>Github</span>
            </a>
          </li>
        </ul>
        <ul className='copyright'>
          <li>&copy; Calvin Wong</li>
          <li>{(new Date().getFullYear())}</li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
