import React from 'react';

import Footer from './Footer';
import avatar_me from '../assets/images/me.jpeg';

/* eslint-disable jsx-a11y/anchor-is-valid */
const Header = () => {
  return (
    <header id='header'>
      <div className='inner'>
        <a className='image avatar'>
          <img src={avatar_me} alt='' />
        </a>
        <h1>
          <strong>Calvin Wong</strong>
          <br />
        </h1>
        <h4>Software Engineer</h4>
        <h4>Hong Kong</h4>
      </div>
      <Footer />
    </header>
  );
};

export default Header;
