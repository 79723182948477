import React from 'react';

import icon_swift from '../assets/images/swift.svg';
import icon_react from '../assets/images/react.svg';
import icon_slack from '../assets/images/slack.svg';
import icon_github from '../assets/images/github.svg';
import icon_firebase from '../assets/images/firebase.png';
import icon_fastlane from '../assets/images/fastlane.png';
import icon_jenkins from '../assets/images/jenkins.png';
import icon_jira from '../assets/images/jira.png';
import icon_ga from '../assets/images/ga.png';
import icon_node from '../assets/images/nodejs.png';
import icon_js from '../assets/images/js.svg';
import icon_html from '../assets/images/html.png';
import icon_css from '../assets/images/css.png';
import icon_appstore from '../assets/images/appstore.png';
import icon_googleplay from '../assets/images/googleplay.png';
import icon_bb from '../assets/images/bb.jpeg';

import icon_jobs from '../assets/images/jobs.png';
import icon_hktraffic from '../assets/images/hktraffic.png';
import icon_payme from '../assets/images/payme.png';
import icon_gumtree from '../assets/images/gumtree.png';
import icon_barrons from '../assets/images/barrons.png';
import icon_fn from '../assets/images/fn.png';
import icon_medium from '../assets/images/medium.jpg';
import icon_mw from '../assets/images/mw.png';

/* eslint-disable jsx-a11y/anchor-is-valid */
const icon = (key) => {
  let image;
  let title;
  switch (key) {
    // project

    // lang
    case 'swift':
      image = icon_swift;
      title = 'Swift';
      break;
    case 'react':
      image = icon_react;
      title = 'ReactJS';
      break;
    case 'javascript':
      image = icon_js;
      title = 'JavaScript';
      break;
    case 'html':
      image = icon_html;
      title = 'HTML5';
      break;
    case 'css':
      image = icon_css;
      title = 'CSS';
      break;
    // tools
    case 'slack':
      image = icon_slack;
      title = 'Slack';
      break;
    case 'appstore':
      image = icon_appstore;
      title = 'AppStore';
      break;
    case 'googleplay':
      image = icon_googleplay;
      title = 'Google Play';
      break;
    case 'firebase':
      image = icon_firebase;
      title = 'Firebase';
      break;
    case 'github':
      image = icon_github;
      title = 'GitHub';
      break;
    case 'fastlane':
      image = icon_fastlane;
      title = 'Fastlane';
      break;
    case 'jenkins':
      image = icon_jenkins;
      title = 'Jenkins';
      break;
    case 'jira':
      image = icon_jira;
      title = 'Jira';
      break;
    case 'ga':
      image = icon_ga;
      title = 'Google Analytics';
      break;
    case 'node':
      image = icon_node;
      title = 'NodeJS';
      break;
    case 'bb':
      image = icon_bb;
      title = 'BuddyBuild';
      break;
    case 'jobs':
      image = icon_jobs;
      title = 'Gumtree Jobs - Job Search';
      break;
    case 'hktraffic':
      image = icon_hktraffic;
      title = 'HK Traffic';
      break;
    case 'payme':
      image = icon_payme;
      title = 'PayMe from HSBC';
      break;
    case 'gumtree':
      image = icon_gumtree;
      title = 'Gumtree: Local Ads & Motors';
      break;
    case 'barrons':
      image = icon_barrons;
      title = "Barron's - Investing Insights";
      break;
    case 'fn':
      image = icon_fn;
      title = 'Finanical News';
      break;
    case 'medium':
      image = icon_medium;
      title = 'Medium';
      break;
    case 'mw':
      image = icon_mw;
      title = 'MarketWatch';
      break;
    default:
      break;
  }

  return (
    <a className='image icon'>
      <img src={image} alt='' title={title} />
    </a>
  );
};

export default icon;
